import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import straightRazor from '../images/straight-razor.webp';
import Contact from '../components/Contact';
import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import headerImage from '../images/barber-14.webp';
import { TableContainer } from './Bookings';
import cutthroatShave from '../images/cutthroat-shave.webp';
import honeYourSkills from '../images/hone-your-skills.webp';
import { ContentWrapper } from '../components/ContentWrapper';
import { H2 } from '../components/headers';

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border: 1px solid #eee;
  border-bottom: none;
  text-align: left;
  margin-bottom: 20px;
  & th {
    border-bottom: 1px solid #eee;
    color: #af8447;
    font-weight: 700;
    &:first-child {
      width: 100px;
    }
  }
  & td,
  th {
    padding: 9px 24px;
    border-bottom: 1px solid #eee;
  }
`;

const ProgramsDescription = styled.div`
  text-align: center;
  margin-bottom: 20px;
  & img {
    width: 100%;
    max-width: 942px;
  }
`;

export const Programs = () => {
  const location = useLocation();

  const certificateData: { classId: string; description: string }[] = [
    {
      classId: '31946',
      description: 'Demonstrate knowledge of skin and hair for barbering',
    },
    {
      classId: '19808',
      description: 'Select and maintain barbering tools and equipment',
    },
    {
      classId: '28845',
      description:
        'Demonstrate safe and professional practice in the salon environment',
    },
    {
      classId: '2866',
      description: 'Shampoo hair and scalp',
    },
    {
      classId: '28848',
      description:
        'Apply knowledge of common hair and scalp conditions to complete a limited scope consultation',
    },
    {
      classId: '2886',
      description: '	Design and shape beards and moustaches',
    },
    {
      classId: '10647',
      description: 'Wet shave facial hair using a cutthroat razor',
    },
    {
      classId: '10645',
      description: 'Describe the development of barbering',
    },
    {
      classId: '10650',
      description:
        'Perform Barbering Services in a commercial Barbering workplace',
    },
    {
      classId: '10648',
      description: 'Complete a haircut using barbering techniques',
    },
    {
      classId: '19805',
      description: 'Blow dry hair using barbering techniques',
    },
    {
      classId: '28017',
      description:
        'Converse and interact to create and maintain an atmosphere suitable for a barbershop',
    },
  ];

  useEffect(() => {
    document.getElementById(location.hash.substr(1))?.scrollIntoView();
  }, [location]);

  return (
    <>
      <PageHeader
        title="Programs"
        icon={straightRazor}
        backgroundImage={headerImage}
      />
      <ContentWrapper>
        <div id="12-week-program">
          <SectionHeader title="12 week program New Zealand Certificate in Commercial Barbering Level 4" />
        </div>
        <ProgramsDescription>
          This course leads to the New Zealand certificate in Commercial
          Barbering L4 after completing 780 hours more working as a Commercial
          Barber
          <br />
          <br />
          <b>Cost: $6589.00 incl. GST</b>
        </ProgramsDescription>
        <H2>NEW ZEALAND CERTIFICATE IN COMMERCIAL BARBERING LEVEL 4</H2>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th>Class ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {certificateData.map(({ classId, description }) => (
                <tr key={classId}>
                  <td>{classId}</td>
                  <td>{description}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableContainer>
        <H2>TO COMPLETE THE PROGRAM</H2>
        <p>
          Evidence must be shown of experience in a salon (you choose where)
          working for a total of 780 hours completing the requirements of these
          unit standards.
        </p>
        <p>
          Final assessment will be made after completion of total hours,
          evidence is provided, a portfolio is submitted and the student has
          shown that the level of competence has been met. Then the New Zealand
          Certificate in Commercial Barbering L4 will be issued.
        </p>
        <p>
          <b>Students must have a full Barbers kit</b> to work as a barber. If
          you do not have a full kit, we can supply a professional kit for an
          extra cost of approx. $900.00
        </p>

        <H2>ENTRY REQUIREMENTS</H2>
        <p>
          Entry is open to students who are over the age of 16 years, and who
          have a strong interest in barbering.
        </p>
        <p>
          A successful applicant to our program will have strong commitment and
          a desire to succeed
        </p>
        <a
          href={`${process.env.PUBLIC_URL}/mr-barber-enrollment-form.pdf`}
          target="_blank"
          rel="noopener noreferrer"
        >
          Current Enrollment Application
        </a>

        <div id="cutthroat-shave-classes">
          <SectionHeader title="CUTTHROAT SHAVE CLASSES" />
        </div>
        <ProgramsDescription>
          <p>
            Wet Shaving is an ancient Art that has come back into fashion.
            Increase your client services and learn this skill.
          </p>
          <img src={cutthroatShave} alt="Cutthroat shave" />
          <p>
            After completing the course and assessment requirements. This class
            can lead to unit standard 19807 to enable you to complete your
            Barbering Qualification.
          </p>
          <p>Cost: $20.00 Payable on enrolment</p>
          <TableContainer>
            <Contact />
          </TableContainer>
        </ProgramsDescription>

        <div id="upskill-classes">
          <SectionHeader title="HONE YOUR BARBER SKILLS" />
        </div>
        <ProgramsDescription>
          <p>
            This course is ideal for hairdressers and Barbers that need to work
            on finishing techniques and more advanced Barbering.
          </p>
          <p>This class can be customized to suit the group needs.</p>
          <img src={honeYourSkills} alt="Hone your skills" />
          <p>
            Must have minimum 2 years Hairdressing or 1 year Barbering
            experience.
          </p>
          <p>Cost: $300.00, Equipment provided</p>
          <p>Class runs for 3 hours</p>
          <TableContainer>
            <Contact />
          </TableContainer>
        </ProgramsDescription>
      </ContentWrapper>
    </>
  );
};
