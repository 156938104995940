import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import headerImage from '../images/terry-at-barber-wars.webp';
import slideshow1 from '../images/slideshow1.webp';
import slideshow2 from '../images/slideshow2.webp';
import slideshow3 from '../images/slideshow3.webp';
import slideshow4 from '../images/slideshow4.webp';
import neville from '../images/neville.webp';
import twitter from '../images/twitter2-300x300.webp';
import facebook from '../images/facebook-300x300.webp';
import fees from '../images/feesfree1-300x270.webp';
import nzqa from '../images/nzqa2.webp';
import studylink from '../images/studylink.webp';
import { ContentWrapper } from '../components/ContentWrapper';
import styled from 'styled-components';
import Slideshow from '../components/Slideshow';

const affiliates: {
  link: string;
  title: string;
  description: string;
  image: string;
  extraDetails?: string;
}[] = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/Mr.Barber.Training.Centre?fref=ts',
    image: facebook,
    description:
      'Visit our page for the latest updates and to see any upcoming events',
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/mrbarbernz',
    image: twitter,
    description:
      'Follow us on twitter to stay in touch. Feel free to leave us some tweets, too :)',
  },
  {
    title: 'Fees Free',
    link: 'https://www.feesfree.govt.nz/',
    image: fees,
    description:
      'From 1 January 2018, tertiary education will be fees-free for eligible first time tertiary students.',
    extraDetails: "Find out whether you're eligible here",
  },
  {
    title: 'StudyLink',
    link: 'http://www.studylink.govt.nz/',
    image: studylink,
    description:
      'Helping students make informed choices about their finances and apply online',
  },
  {
    title: 'NZQA',
    link: 'http://www.nzqa.govt.nz/providers/details.do?providerId=735605001',
    image: nzqa,
    description: 'Information on our organization',
  },
];

const SlideShowContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  max-width: 980px;
  overflow-x: auto;
  margin-left: auto;
  margin-right: auto;
`;

const MainSection = styled.div`
  display: flex;
  padding: 20px 0;
  @media only screen and (max-width: 981px) {
    flex-direction: column;
  }
`;

const IntroSection = styled.div`
  flex: 0.8;
  text-align: justify;
  color: #666;

  & img {
    float: left;
    margin-right: 15px;
    width: 225px;
    background: rgb(10, 10, 10);
  }
  @media only screen and (max-width: 377px) {
    & img {
      margin-bottom: 15px;
    }
  }
  @media only screen and (max-width: 981px) {
    flex: 1;
    margin-bottom: 20px;
  }
`;

const ClassInfo = styled.div`
  flex: 0.2;
  padding: 15px;
  margin-left: 20px;
  background: #1e1d1c;
  border-radius: 5px;
  color: white;
  box-shadow: 10px 10px 0px 0px rgb(0 0 0 / 30%);

  & div {
    text-align: center;
    font-size: 20px;
    margin-bottom: 8px;
  }
  & ul {
    padding-left: 25px;
    & li {
      margin-bottom: 5px;
      line-height: 1.5em;
    }
  }

  @media only screen and (max-width: 981px) {
    margin-left: 0;
  }
`;

const Affiliates = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
`;

const AffiliateSection = styled.div`
  display: flex;
  width: 50%;
  margin-bottom: 15px;
  & img {
    width: 75px;
    height: 75px;
    margin-right: 15px;
  }
  @media (max-width: 980px) {
    width: 100%;
  }
`;

const AffiliateInfo = styled.div`
  display: flex;
  flex-direction: column;
  & a:first-child {
    color: #333;
    font-size: 18px;
    &:hover {
      text-decoration: underline;
    }
  }
  & span {
    margin: 8px 0;
    color: #666;
  }
`;

export const Home: React.FC = () => (
  <>
    <PageHeader
      title="Mr. BARBER"
      subTitle="Barbering For The Next Century"
      backgroundImage={headerImage}
    />
    <ContentWrapper>
      <SlideShowContainer>
        <Slideshow images={[slideshow1, slideshow2, slideshow3, slideshow4]} />
      </SlideShowContainer>
      <SectionHeader title="Mr. barber - the barber school" />
      <MainSection>
        <IntroSection>
          <img src={neville} alt="Neville" height={300} width={225} />
          <span>
            Neville Spence, the founder of the leading Mr. BARBER Shops in
            Greenwoods Corner, Epsom and Auckland City, also developed Mr.
            BARBER Training Centre to be the best and only specific Barber
            Training Centre in New Zealand to train Barbers in the skill of
            Barbering.
            <br />
            <br /> The Auckland based Barber School founded in 2000 offers
            students the opportunity to learn traditional and modern Barbering
            skills & techniques from some of the best barbers in the country.
            <br />
            <br />
            Barbering is an international career and a highly skilled craft that
            you can take up at any age. Barbers are in great demand worldwide
            which provides a wonderful opportunity to work while traveling the
            world, or work in New Zealand or even open your own business.
            <br />
            <br />
            Barbering has had a huge renaissance over the past few years and
            become very fashionable throughout the world, mainly due to the
            trend for short hairstyles for men, the trend of beards and an
            increase in their attention to fashion and grooming.
            <br />
            <br /> Mr. BARBER Dynamic Training Program is a fast paced intensive
            training Program designed to get you out on the floor doing what you
            want to do most.. BECOME A BARBER. The Training Centre operates in
            the same way a Barber shop would operate so you get a lot of on the
            floor training on live models. Our classes are small so we can give
            you as much attention as you need to succeed. Students of all ages,
            sexes and ethnicities are represented in most of our classes. Mr.
            BARBER Dynamic Training Program gives you Maximum exposure to
            Barbering on real clients…. No training on mannequins, 75% of the
            training time is dedicated to developing Barbering skills… on the
            floor cutting on real clients… this starts on the first day.
            <br />
            <br /> Mr. BARBER Training Centre is NZQA registered and accredited
            to deliver and assess unit standards.
          </span>
        </IntroSection>
        <ClassInfo>
          <div>12 Week Program</div>
          Leads to: NZ Ceritficate in Commercial Barbering L4 Covers:
          <ul>
            <li>Men's haircutting</li>
            <li>
              Freehand Clipper, clipper overcomb, freehand scissor, scissor
              overcomb
            </li>
            <li>Beard shaping and design</li>
            <li>Cutthroat shaving</li>
            <li>History of barbering</li>
            <li>Fading and blending</li>
          </ul>
          And lots of things you need to know to enable you to work successfully
          as a Barber
        </ClassInfo>
      </MainSection>
      <Affiliates>
        {affiliates.map((affiliate) => (
          <AffiliateSection key={affiliate.title}>
            <img
              width={75}
              height={75}
              src={affiliate.image}
              alt={affiliate.title}
            />
            <AffiliateInfo>
              <a href={affiliate.link}>{affiliate.title}</a>
              <span>{affiliate.description}</span>
              {affiliate.extraDetails && (
                <a href={affiliate.link}>{affiliate.extraDetails}</a>
              )}
            </AffiliateInfo>
          </AffiliateSection>
        ))}
      </Affiliates>
    </ContentWrapper>
  </>
);
