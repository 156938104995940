import styled from 'styled-components';

interface Props {
  title: string;
  subTitle?: string;
  backgroundImage?: string;
  icon?: string;
}

const Header = styled.div`
  position: relative;
  text-align: center;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-image: linear-gradient(
      180deg,
      rgba(58, 58, 58, 0.9) 0%,
      rgba(22, 22, 22, 0.8) 100%
    ),
    url(${(props: { backgroundImage?: string }) => props.backgroundImage || ''});
`;

const HeaderInformation = styled.div`
  font-family: 'Georgia', Georgia, 'Times New Roman', serif;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 9vw;
  text-align: center;
  @media (max-width: 980px) {
    font-size: 10vw;
  }
`;

const SubTitle = styled.span`
  font-size: 22px;
  &::before {
    background-color: white;
    content: '';
    top: -3px;
    display: inline-block;
    height: 2px;
    position: relative;
    width: 5%;
    margin-right: 10px;
  }
  &::after {
    background-color: white;
    content: '';
    top: -3px;
    display: inline-block;
    height: 2px;
    position: relative;
    width: 5%;
    margin-left: 10px;
  }
  @media (min-width: 767px) {
    width: 400px;
  }
  @media (max-width: 767px) {
    font-size: 17px;
    &::before,
    &::after {
      width: 0;
    }
  }
`;

export const PageHeader = ({
  title,
  subTitle,
  backgroundImage,
  icon,
}: Props) => (
  <Header backgroundImage={backgroundImage}>
    <HeaderInformation>
      {icon && <img src={icon} alt={icon} width={64} height={64} />}
      <Title>{title}</Title>
      {subTitle && <SubTitle>{subTitle}</SubTitle>}
    </HeaderInformation>
  </Header>
);
