import { EventModel } from '../models/eventModel';
import { graphql } from './graphql.service';

export const isAuthenticated = async () => {
  const query = `#graphql
    query GetPhone {
      event(limit:1) {
        phone
      }
    }
  `;
  const res = await graphql(query, {});
  return !!res?.data?.event;
};

export const getEvent = async (id: number) => {
  const query = `#graphql
    query GetEvent($id: Int!) {
      event(where: {id: {_eq: $id}}) {
        id
        name
        phone
        start
      }
    }
  `;
  const res = await graphql(query, { id });
  return res.data.event[0];
};

export const getEvents = async (shopId: number): Promise<EventModel[]> => {
  const after = new Date();
  after.setDate(after.getDate() - after.getDay());
  const hasPassword = !!localStorage.getItem('password');
  const query = `#graphql
    query GetEvents($after: timestamp!, $shopId: Int) {
      event(where: {start: {_gt: $after}, shopId: {_eq: $shopId}}) {
        id
        start
        ${hasPassword ? 'phone name' : ''}
        service { name }
        barber { name }
      }
    }
`;
  const res = await graphql(query, { after, shopId });
  const updatedData: EventModel[] = res.data.event.map((item: any) => ({
    ...item,
    start: new Date(item.start),
  }));
  return updatedData;
};

export const createEvent = async (event: EventModel): Promise<number> => {
  const query = `#graphql
    mutation CreateEvent($event: event_insert_input!) {
      insert_event_one(object: $event) {
        id
      }
    }
`;
  const res = await graphql(query, { event });
  return res.data.insert_event_one.id;
};
