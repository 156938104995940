import styled from 'styled-components';

const Wrapper = styled.div`
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0 50px 0;
  color: #666;
`;

export const ContentWrapper: React.FC = ({ children }) => (
  <Wrapper>{children}</Wrapper>
);
