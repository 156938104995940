import styled from 'styled-components';
import { PageHeader } from '../components/PageHeader';
import shaving from '../images/shaving.webp';
import straightRazor from '../images/straight-razor.webp';
import gallery10400x284 from '../images/Gallery10-400x284.webp';
import gallery11400x284 from '../images/Gallery11-400x284.webp';
import gallery12400x284 from '../images/Gallery12-400x284.webp';
import gallery13400x284 from '../images/Gallery13-400x284.webp';
import gallery1400x284 from '../images/Gallery1-400x284.webp';
import gallery14400x284 from '../images/Gallery14-400x284.webp';
import gallery15400x284 from '../images/Gallery15-400x284.webp';
import gallery16400x284 from '../images/Gallery16-400x284.webp';
import gallery17400x284 from '../images/Gallery17-400x284.webp';
import gallery18400x284 from '../images/Gallery18-400x284.webp';
import gallery19400x284 from '../images/Gallery19-400x284.webp';
import gallery20400x284 from '../images/Gallery20-400x284.webp';
import gallery2400x284 from '../images/Gallery2-400x284.webp';
import gallery3400x284 from '../images/Gallery3-400x284.webp';
import gallery4400x284 from '../images/Gallery4-400x284.webp';
import gallery5400x284 from '../images/Gallery5-400x284.webp';
import gallery6400x284 from '../images/Gallery6-400x284.webp';
import gallery7400x284 from '../images/Gallery7-400x284.webp';
import gallery8400x284 from '../images/Gallery8-400x284.webp';
import gallery9400x284 from '../images/Gallery9-400x284.webp';

const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 847px;

  ::after {
    display: block;
    content: '';
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const images: string[] = [
  gallery10400x284,
  gallery11400x284,
  gallery12400x284,
  gallery13400x284,
  gallery1400x284,
  gallery14400x284,
  gallery15400x284,
  gallery16400x284,
  gallery17400x284,
  gallery18400x284,
  gallery19400x284,
  gallery20400x284,
  gallery2400x284,
  gallery3400x284,
  gallery4400x284,
  gallery5400x284,
  gallery6400x284,
  gallery7400x284,
  gallery8400x284,
  gallery9400x284,
];

const ProgramsContainer = styled.div`
  img {
    margin: 1rem;
    cursor: pointer;
  }
`;

export default function Gallery() {
  return (
    <ProgramsContainer>
      <PageHeader
        title="Gallery"
        backgroundImage={shaving}
        icon={straightRazor}
      />
      <div className="content-wrapper">
        <h2 style={{ marginBottom: 10 }}>INTERVIEW WITH THE FOUNDER</h2>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IframeContainer>
            <iframe
              src="https://www.youtube.com/embed/SXkkqXE5Bf0"
              title="Interview with the Founder"
            ></iframe>
          </IframeContainer>
        </div>
        {images.map((image) => (
          <img
            key={image}
            src={image}
            alt={image}
            onClick={() => window.open(image, '_blank')}
            width={400}
            height={284}
          />
        ))}
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IframeContainer>
            <iframe
              src="https://www.youtube.com/embed/4d_lkgn4IoQ"
              title="Inside Mr Barber"
            ></iframe>
          </IframeContainer>
        </div>
      </div>
    </ProgramsContainer>
  );
}
