import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ContentWrapper } from '../components/ContentWrapper';
import { H2 } from '../components/headers';
import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import SelectableCalendar from '../components/SelectableCalendar';
import { SHOP_HOURS } from '../constants/shopHours';
import shears from '../images/shears.webp';
import tools from '../images/tools.webp';
import { BarberModel } from '../models/barberModel';
import { ServiceModel } from '../models/serviceModel';
import { ShopModel } from '../models/shopModel';
import { getBarber } from '../services/barber.service';
import { graphql } from '../services/graphql.service';

const SubHeading = styled.div`
  font-size: 16px;
`;

const PriceContainer = styled.span`
  font-size: 20px;
  color: #af8447;
  padding: 25px 15px;

  & > div {
    position: relative;
    font-size: 60px;
    font-weight: 300;
    & div {
      color: #bebebe;
      font-size: 20px;
      position: absolute;
      top: -15px;
      left: -5px;
    }
  }
`;

const TableHeader = styled.span`
  background-color: #bebebe;
  color: white;
  padding: 10px;
  &:not(${SubHeading}) {
    font-size: 26px;
  }
`;

export const TableContainer = styled.div`
  overflow-x: auto;
  display: flex;
  justify-content: center;
  > div {
    display: flex;
    flex-direction: column;
    ${PriceContainer}, ${TableHeader}, span {
      border: 1px solid #bebebe;
      &:last-child {
        border-top: none;
      }
    }
    &:last-child {
      ${PriceContainer}, ${TableHeader}, & > span {
        border-left: none;
      }
    }
    > div {
      ul {
        margin: 0;
        padding: 15px 0px;
        li {
          padding-left: 0;
        }
      }
    }
  }
  @media (max-width: 680px) {
    flex-direction: column;
    > div:last-child {
      border-left: 1px solid #bebebe;
    }
  }
`;

const BookingsContainer = styled.span`
  text-align: center;
  display: flex;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: auto;

  .rbc-calendar {
    width: 100%;
  }
`;

export const StoreForm = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  & label {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & span {
      margin-right: 15px;
    }
  }
  & button {
    margin: 0 15px;
    border: none;
    background: #af8447;
    color: white;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    cursor: pointer;
    & svg {
      margin-left: 0 !important;
    }
    &:hover {
      background: rgba(175, 132, 71, 0.8) !important;
    }
  }
`;

export const FormDropdown = styled.select`
  border-radius: 10px;
  padding: 5px 10px;
  border: 1px solid #eee;
  background: white;
  width: 220px;
`;

const ShopInformation = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    min-width: 230px;
    max-width: 350px;
    border: 1px solid #eee;
    border-radius: 5px;
    padding: 20px;
    margin: 15px;
    div {
      justify-content: space-between;
      margin-bottom: 5px;
    }
  }
`;

const Services = styled.div`
  flex: 0.5;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
  }
`;

const ShopHours = styled.div`
  flex: 0.5;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 15px;
  div {
    display: flex;
    justify-content: space-between;
  }
`;

export default function Bookings() {
  const [shops, setShops] = useState<ShopModel[]>([]);
  const [services, setServices] = useState<ServiceModel[]>([]);
  const [selectedStore, setSelectedStore] = useState<number>(1);
  const [barbers, setBarbers] = useState<BarberModel[]>([]);

  useEffect(() => {
    (async () => {
      const query = `#graphql
        {
          shop {
            id
            name
            address
            allowWalkIns
          }
          service {
            id
            name
            price
          }
        }
      `;
      const { data } = await graphql(query, {});
      setShops(data.shop);
      setServices(data.service);
    })();
  }, []);

  useEffect(() => {
    getBarber(selectedStore).then(setBarbers);
  }, [selectedStore]);

  return (
    <>
      <PageHeader title="BOOKINGS" backgroundImage={shears} icon={tools} />
      <ContentWrapper>
        <BookingsContainer>
          <SectionHeader id="book-an-appointment" title="BOOK AN APPOINTMENT" />
          <StoreForm>
            <label>
              <span>Select a store:</span>
              <FormDropdown
                value={selectedStore}
                onChange={(e) => setSelectedStore(Number(e.target.value))}
              >
                {shops.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </FormDropdown>
            </label>
          </StoreForm>
          <ShopInformation>
            <Services>
              <H2>SERVICES</H2>
              <hr />
              {services.map(({ name, price }) => (
                <div key={name}>
                  <span>{name}</span>
                  <span>
                    {price.toLocaleString('en-nz', {
                      style: 'currency',
                      currency: 'NZD',
                    })}
                  </span>
                </div>
              ))}
              (* Mon-Fri before 3pm)
            </Services>
            <ShopHours>
              <H2>STORE HOURS</H2>
              <hr />
              {SHOP_HOURS.map(({ day, timeRange }) => (
                <div key={`hours-${day}`}>
                  <span>{day}</span>
                  <span>{timeRange}</span>
                </div>
              ))}
              <H2 style={{ marginTop: 15 }}>Barber Availability</H2>
              <hr />
              {barbers.map(({ name, appointmentDays }) => (
                <div key={`${selectedStore}-barber-${name}`}>
                  <span>{name}</span>
                  <span>{appointmentDays.join(', ')}</span>
                </div>
              ))}
            </ShopHours>
          </ShopInformation>
          <CalendarContainer>
            <SelectableCalendar
              shop={selectedStore}
              services={services}
              barbers={barbers}
            />
          </CalendarContainer>
        </BookingsContainer>
      </ContentWrapper>
    </>
  );
}
