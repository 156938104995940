import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import useClickOutside from '../hooks/use-click-outside';
import { H2 } from './headers';

const Container = styled.div`
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  & > div:last-child {
    padding: 20px;
    background: white;
    border-radius: 0 0 15px 15px;
  }
`;

const Close = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  color: white;
  width: 30px;
  height: 30px;
  border: 2px solid white;
  border-radius: 100%;
`;

const ModalHeader = styled.div`
  border-radius: 15px 15px 0 0;
  background: #af8447;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h2 {
    color: white;
    margin-bottom: 0;
  }
`;

export default function Modal({
  title,
  onClose,
  children,
}: {
  title: string;
  onClose: () => void;
  children?: React.ReactElement<any, any> | React.ReactElement<any, any>[];
}) {
  const ref = React.createRef<HTMLDivElement>();
  useClickOutside(ref, () => onClose());

  return (
    <Container>
      <Content ref={ref}>
        <ModalHeader>
          <H2>{title}</H2>
          <Close onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
        </ModalHeader>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {children}
        </div>
      </Content>
    </Container>
  );
}
