import styled from 'styled-components';

export const H2 = styled.h2`
  color: #af8447;
  font-family: 'Bebas Neue', cursive;
  font-size: 26px;
  font-weight: 500;
  line-height: 1em;
  margin-top: 0;
  margin-bottom: 10px;
  letter-spacing: 2px;
`;
