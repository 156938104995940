import { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Contact from '../components/Contact';
import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import headerImage from '../images/barber-14.webp';
import scissors from '../images/scissors.webp';
import { ContentWrapper } from '../components/ContentWrapper';
import { TableContainer } from './Bookings';

const ShopWrapper = styled.div`
  table {
    display: flex;
    margin-bottom: 1rem;
    border-spacing: 0;
    border: 1px solid #eee;
    text-align: left;
    width: 100%;
    & td {
      border: none;
      border-bottom: 1px solid #eee;
      padding: 6px 24px;
    }
    & tr:last-child td {
      border-bottom: none;
    }
  }
`;

export const Shops = () => {
  const location = useLocation();
  const trainingMap = useRef(null);
  const epsomMap = useRef(null);
  const greyMap = useRef(null);

  const makeMap = (
    coords: { lat: number; lng: number },
    ref: React.MutableRefObject<null>,
  ) => {
    new Loader({ apiKey: 'AIzaSyDBm9SM7rErvNdAAq8XPHQvmgVxhUGh-rU' })
      .load()
      .then(() => {
        if (!ref.current) return;
        new google.maps.Marker({
          position: coords,
          map: new google.maps.Map(ref.current, {
            zoom: 15,
            center: coords,
          }),
        });
      });
  };

  useEffect(() => {
    document.getElementById(location.hash.substr(1))?.scrollIntoView();
    makeMap({ lat: -36.84778, lng: 174.76294 }, trainingMap);
    makeMap({ lat: -36.9005, lng: 174.77357 }, epsomMap);
    makeMap({ lat: -36.86167, lng: 174.73316 }, greyMap);
  }, [location]);

  return (
    // <ShopWrapper>
    <>
      <PageHeader
        title="OUR SHOPS"
        subTitle="WE TAKE WALK-INS"
        icon={scissors}
        backgroundImage={headerImage}
      />
      <ContentWrapper>
        <div id="training-centre">
          <SectionHeader title="TRAINING CENTRE" />
        </div>
        <TableContainer style={{ marginBottom: 20 }}>
          <Contact>
            <tr>
              <td>Open hours</td>
              <td>
                <p>10am - 12pm</p> <p>1pm - 3:30 pm</p>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>1/60 Federal St Auckland, CBD 1010</td>
            </tr>
          </Contact>
        </TableContainer>
        <div ref={trainingMap} style={{ height: 400, width: '100%' }} />

        <div id="epsom-shop">
          <SectionHeader title="EPSOM STORE" />
        </div>
        <TableContainer style={{ marginBottom: 20 }}>
          <Contact>
            <tr>
              <td>Open hours</td>
              <td>
                <p>Monday - Friday: 9am - 6pm</p>
                <p>Saturday: 9am - 4pm</p>
                <p>Sunday: 9am - 3pm</p>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>604 Manukau Road Epsom, Auckland</td>
            </tr>
          </Contact>
        </TableContainer>
        <div ref={epsomMap} style={{ height: 400, width: '100%' }} />

        <div id="grey-lynn">
          <SectionHeader title="GREY LYNN" />
        </div>
        <TableContainer style={{ marginBottom: 20 }}>
          <Contact>
            <tr>
              <td>Open hours</td>
              <td>
                <p>Monday - Wednesday: 9am - 6pm</p>
                <p>Thursday and Friday: 9am - 7pm</p>
                <p>Saturday: 9am - 4pm</p>
                <p>Sunday: 9am - 3pm</p>
              </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>580 Richmond Road Grey Lynn, Auckland</td>
            </tr>
          </Contact>
        </TableContainer>
        <div ref={greyMap} style={{ height: 400, width: '100%' }} />
      </ContentWrapper>
    </>
  );
};
