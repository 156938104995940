export const SHOP_HOURS = [
  {
    day: 'Monday to Friday',
    timeRange: '9am - 5pm',
  },
  {
    day: 'Saturday',
    timeRange: '9am - 3pm',
  },
  {
    day: 'Sunday',
    timeRange: '9am - 2pm',
  },
];
