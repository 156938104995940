import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import alex from '../images/alex-burnett.webp';
import geurt from '../images/geurt.webp';
import jacqui from '../images/jacqui-spence.webp';
import kendall from '../images/kendall.webp';
import neville from '../images/neville.webp';
import brushes from '../images/brushes.webp';
import terry from '../images/terry-gautsua.webp';
import tomas from '../images/tomas-houghton.webp';
import wayne from '../images/wayne-joubert.webp';
import styled from 'styled-components';
import combIcon from '../images/comb-icon.webp';
import { H2 } from '../components/headers';
import { ContentWrapper } from '../components/ContentWrapper';

interface Team {
  name: string;
  positions: string[];
  description?: string;
  imgSrc: string;
  imgWidth?: number;
  imgHeight?: number;
}

const TeamSection = styled.div`
  display: flex;
  margin: 20px 0;
  & img {
    margin-right: 50px;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    & img {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  @media (max-width: 400px) {
    & img {
      width: 100%;
      height: 100%;
    }
  }
`;

const MemberInformation = styled.div`
  display: flex;
  flex-direction: column;
  color: #666;
  text-align: left;
`;

const MemberPositions = styled.div`
  font-style: italic;
  margin-bottom: 10px;
`;

export const About = () => {
  const team: Team[] = [
    {
      name: 'Neville Spence',
      positions: ['Founder', 'Barber', 'Adult Educator'],
      description:
        'Founder of Mr. BARBER, Mr. BARBER Training Center and is a founding member of Barbers Association of New Zealand . Developed the Dynamic Training Program. Barbering since 1967 in New Zealand and Australia. Won multiple competitions in New Zealand and Australia.',
      imgSrc: neville,
      imgWidth: 300,
      imgHeight: 400,
    },
    {
      name: 'Geurt Renzenbrink',
      positions: ['Hairdresser', 'Barber', 'Tutor', 'Trichologist'],
      description:
        'Joined Mr. Barber Compony in 2000. Has been in the industry since 1972.',
      imgSrc: geurt,
      imgWidth: 300,
      imgHeight: 400,
    },
    {
      name: 'Jacqui Spence',
      positions: ['Director', 'Co-Founder'],
      description: 'Director and Co-Ordinator od Mr. BARBER Training Center.',
      imgSrc: jacqui,
      imgWidth: 300,
      imgHeight: 300,
    },
    {
      name: 'Tomas Houghton',
      positions: ['Barber', 'Tutor', 'Adult Educator', 'Barbershop Owner'],
      imgSrc: tomas,
      imgWidth: 300,
      imgHeight: 486,
    },
    {
      name: 'Terry Gautusa',
      positions: ['Barber', 'Tutor', 'Adult Educator'],
      description:
        'Master section winner at Barbercraft. Place winner at Barber Wars NZ.',
      imgSrc: terry,
      imgWidth: 300,
      imgHeight: 450,
    },
    {
      name: 'Wayne Joubert',
      positions: ['Barber', 'Tutor', 'Adult Educator', 'Barbershop Owner'],
      description:
        'Winner Neville Spence Barber of the year at Barber Wars NZ 2019.',
      imgSrc: wayne,
      imgWidth: 300,
      imgHeight: 450,
    },
    {
      name: 'Kendall Brown',
      positions: ['Barber', 'Tutor', 'Adult Educator'],
      description:
        'Worked at Mr. Barber since completing the Training program. Mentored trainee Barbers during the 14 years she worked at Mr. Barber. Opened her own Barbershop Uppercut Barbers.',
      imgSrc: kendall,
      imgWidth: 300,
      imgHeight: 401,
    },
    {
      name: 'Alex Burnett',
      positions: ['Barber', 'Tutor', 'Adult Educator'],
      imgSrc: alex,
      imgWidth: 300,
      imgHeight: 364,
    },
  ];
  return (
    <>
      <PageHeader title="About Us" backgroundImage={brushes} icon={combIcon} />
      <ContentWrapper>
        <SectionHeader title="Our Team" />
        {team.map((member) => (
          <TeamSection key={member.name}>
            <img
              src={member.imgSrc}
              alt={member.name}
              width={member.imgWidth}
              height={member.imgHeight}
            />
            <MemberInformation>
              <H2>{member.name.toUpperCase()}</H2>
              <MemberPositions>{member.positions.join(' | ')}</MemberPositions>
              {member.description && <span>{member.description}</span>}
            </MemberInformation>
          </TeamSection>
        ))}
      </ContentWrapper>
    </>
  );
};
