import { useState } from 'react';
import { BarberModel } from '../models/barberModel';
import { EventModel } from '../models/eventModel';
import { ServiceModel } from '../models/serviceModel';
import { FormDropdown } from '../pages/Bookings';
import { createEvent } from '../services/event.service';
import { EventForm, FormInput } from './EventForm';
import Modal from './Modal';

export default function EditEvent({
  onClose,
  event,
  services,
  barbers,
  update,
  showSnack,
}: {
  onClose: () => void;
  event: EventModel;
  services: ServiceModel[];
  barbers: BarberModel[];
  update: () => Promise<void>;
  showSnack: () => void;
}) {
  const [eventDetails, setEventDetails] = useState<EventModel>(event);
  const handleAppointment = async () => {
    const tzOffset = eventDetails.start.getTimezoneOffset() * 60000;
    console.log({ tzOffset });
    const msLocal = eventDetails.start.getTime() - tzOffset;
    const dateLocal = new Date(msLocal);
    const iso = dateLocal.toISOString().slice(0, -1);
    const updatedEvent = {
      ...eventDetails,
      start: iso,
    };
    await createEvent(updatedEvent as EventModel & { start: string });
    await update();
    showSnack();
    onClose();
  };

  const { start } = eventDetails;
  const weekday = start.toLocaleString('en-nz', {
    weekday: 'short',
  });

  const processedServices =
    weekday === 'Sat' || weekday === 'Sun' || start.getHours() >= 15
      ? services.filter(({ name }) => !name.includes('Seniors'))
      : services;
  const processedBarbers = barbers.filter((barber) =>
    barber.appointmentDays.includes(weekday),
  );

  return (
    <Modal title="New Appointment" onClose={onClose}>
      <div
        style={{ textAlign: 'left', marginBottom: 15 }}
      >{`Date: ${start.getDate()} ${start.toLocaleString('en-nz', {
        weekday: 'short',
      })}, ${
        start.getHours() > 12 ? start.getHours() - 12 : start.getHours()
      }:${start.getMinutes() === 0 ? '00' : start.getMinutes()} ${
        start.getHours() >= 12 ? 'PM' : 'AM'
      }`}</div>
      <EventForm
        onSubmit={(event) => {
          event.preventDefault();
          handleAppointment();
        }}
      >
        <label style={{ display: 'flex' }}>
          <span>Name</span>
          <FormInput
            required
            value={eventDetails.name}
            onChange={(event) =>
              setEventDetails({ ...eventDetails, name: event.target.value })
            }
            autoFocus
          />
        </label>
        <label style={{ display: 'flex' }}>
          <span>Phone</span>
          <FormInput
            required
            type="tel"
            value={eventDetails.phone}
            onChange={(event) =>
              setEventDetails({ ...eventDetails, phone: event.target.value })
            }
          />
        </label>
        <label style={{ display: 'flex' }}>
          <span>Service</span>
          <FormDropdown
            style={{ width: '100%' }}
            required
            value={eventDetails.serviceId}
            onChange={(event) =>
              setEventDetails({
                ...eventDetails,
                serviceId: Number(event.target.value),
              })
            }
          >
            {processedServices.map((service) => (
              <option key={`new-event-${service.name}`} value={service.id}>
                {service.name}
              </option>
            ))}
          </FormDropdown>
        </label>
        <label style={{ display: 'flex' }}>
          <span>Barber</span>
          <FormDropdown
            style={{ width: '100%' }}
            required
            value={eventDetails.barberId || 0}
            onChange={(event) =>
              setEventDetails({
                ...eventDetails,
                barberId: Number(event.target.value),
              })
            }
          >
            <option value={0}>Any</option>
            {processedBarbers.map((barber) => (
              <option key={`new-event-${barber.name}`} value={barber.id}>
                {barber.name}
              </option>
            ))}
          </FormDropdown>
        </label>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button onClick={onClose} type="button">
            CANCEL
          </button>
          <button>SUBMIT</button>
        </div>
      </EventForm>
    </Modal>
  );
}
