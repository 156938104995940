import React, { useState } from 'react';
import { isAuthenticated } from '../services/event.service';
import { EventForm, FormInput } from './EventForm';
import Modal from './Modal';

export const LOGIN_EVENT = 'login-event';

export default function SignIn({ onClose }: { onClose: () => void }) {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  const submit = async () => {
    localStorage.setItem('password', password);
    if (await isAuthenticated()) {
      window.dispatchEvent(new Event(LOGIN_EVENT));
      return onClose();
    } else {
      localStorage.removeItem('password');
    }
    setError(true);
    setTimeout(() => setError(false), 4000);
  };

  return (
    <Modal title="Sign in" onClose={onClose}>
      <EventForm
        onSubmit={async (event) => {
          event.preventDefault();
          await submit();
        }}
      >
        <label style={{ display: 'flex' }}>
          <span>Password</span>
          <FormInput
            required
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            type="password"
            autoFocus
          />
        </label>
        {error && (
          <span style={{ color: 'red', position: 'fixed' }}>
            Invalid password.
          </span>
        )}
        <div>
          <button>SUBMIT</button>
        </div>
      </EventForm>
    </Modal>
  );
}
