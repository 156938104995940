export const addMinutes = (date: Date, minutes: number) => {
  return new Date(date.getTime() + minutes * 60000);
};

export const getTimeIntervals = () => {
  const nineAm = new Date();
  nineAm.setHours(8);
  nineAm.setMinutes(30);

  const intervals: Date[] = [];
  let prevInterval = nineAm;
  while (prevInterval.getHours() !== 17) {
    const newInterval = addMinutes(prevInterval, 30);
    prevInterval = newInterval;
    intervals.push(newInterval);
  }
  return intervals;
};

export const addWeek = (date: Date, subtract?: boolean) => {
  const d = new Date(date);
  d.setDate(date.getDate() + (subtract ? -7 : 7));
  return d.toISOString().substr(0, 10);
};

export const getDayIntervals = (d: Date, interval: number) => {
  const date = d;
  const start = new Date(date.setDate(date.getDate() - 1));
  let days = [];
  for (let i = 0; i < interval; i++) {
    days.push(new Date(start.setDate(start.getDate() + 1)));
  }

  return days;
};
