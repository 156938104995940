import styled from 'styled-components';

export const EventForm = styled.form`
  & label {
    margin-bottom: 15px;
    align-items: center;
    & span {
      width: 75px;
      text-align: left;
    }
  }
  & button {
    color: white;
    border: none;
    background: #af8447;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bold;
    font-size: 12px;
    cursor: pointer;
  }
  & > div:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const FormInput = styled.input`
  border-radius: 10px;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #eee;
`;
