import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MainNavigation } from './components/MainNavigation';
import { Home } from './pages/Home';
import { About } from './pages/About';
import { Programs } from './pages/Programs';
import Bookings from './pages/Bookings';
import Enrolment from './pages/Enrolment';
import Gallery from './pages/Gallery';
import { Shops } from './pages/Shops';

const App = () => {
  return (
    <div className="app">
      <Router>
        <MainNavigation />
        <Switch>
          <Route path="/about" component={About} />
          <Route path="/programs" component={Programs} />
          <Route path="/bookings" component={Bookings} />
          <Route path="/enrolment" component={Enrolment} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/shops" component={Shops} />
          <Route path="/" component={Home} />
        </Switch>
      </Router>
    </div>
  );
};

export default App;
