import styled from 'styled-components';
import barber from '../images/moustache.webp';

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  margin: 20px 0;
  font-weight: bold;
  color: #af8447;
  line-height: 1.4em;
  font-size: 25px;
  font-family: 'Bebas Neue', cursive;
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
`;

const Title = styled.h2`
  font-size: 36px;
  text-align: center;
`;

const Hr = styled.hr`
  height: 3px;
  width: 50px;
  background-color: #af8447;
  border: none;
`;

export const SectionHeader = ({
  id,
  title,
  icon,
}: {
  id?: string;
  title: string;
  icon?: string;
}) => (
  <Header id={id || ''}>
    <Icon src={icon || barber} alt={title} />
    <Title>{title.toUpperCase()}</Title>
    <Hr />
  </Header>
);
