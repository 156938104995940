import styled from 'styled-components';

export const Table = styled.table`
  td,
  th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  table {
    width: 100%;
  }

  th {
    font-size: 26px;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(10, 10, 10, 0.76);
    color: white;
  }

  ul {
    text-align: start;
  }
`;

export default function Contact({
  children,
}: {
  children?: React.ReactElement<any, any> | React.ReactElement<any, any>[];
}) {
  return (
    <Table>
      <tbody>
        <tr>
          <td>Contact</td>
          <td>
            <a href="mailto:mrbarber@mrbarber.co.nz">mrbarber@mrbarber.co.nz</a>
          </td>
        </tr>
        <tr>
          <td>Phone</td>
          <td>
            <a href="tel:099291150">(09) 929-1150 </a>
          </td>
        </tr>
        <tr>
          <td>Mobile</td>
          <td>
            <a href="tel:0210440708 ">021-044-0708 </a>
          </td>
        </tr>
        {children}
      </tbody>
    </Table>
  );
}
