export const API = 'https://hasura.mrbarber.co.nz/v1/graphql';

export async function graphql(query: string, variables: any) {
  const init: RequestInit = {
    method: 'POST',
    body: JSON.stringify({ query, variables }),
  };
  const password = localStorage.getItem('password');
  if (password)
    init.headers = {
      'x-hasura-admin-secret': password,
    };
  const result = await fetch(API, init);
  const json = await result.json();
  if (!json.data) console.error(json);
  return json;
}
