import React from 'react';
import styled from 'styled-components';
import { ContentWrapper } from '../components/ContentWrapper';
import { PageHeader } from '../components/PageHeader';
import { SectionHeader } from '../components/SectionHeader';
import brushes from '../images/brushes.webp';
import razor from '../images/razor.webp';

const FileTable = styled.div`
  display: flex;
  flex-direction: column;
  & > div {
    display: flex;
    justify-content: space-between;
    padding: 6px 24px;
    align-items: center;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    border-bottom: 1px solid #eee;
    & div {
      display: flex;
      flex-direction: column;
      padding-right: 15px;
    }
    & button {
      height: 24px;
      & a {
        color: #666;
      }
    }
    &:first-child {
      border-top: 1px solid #eee;
    }
  }
`;

export default function Enrolment() {
  const files = [
    {
      title: 'Class Dates',
      name: 'Class Dates 2021-2022.pdf',
      url: '/class-dates-2021-2022.pdf',
    },
    {
      title: 'Enrolment Form 2021',
      name: 'Enrolment Form.pdf',
      url: '/Mr-BARBER-Enrollment-form.pdf',
    },
    {
      title: 'New Zealand Certificate in Commercial Barbering Level 4',
      name: 'New Zealand Certificate in Commercial Barbering Level 4.pdf',
      url: '/To-complete-the-programme-and-achieve-the-qualification.pdf',
    },
    {
      title: 'Welcome to Mr. BARBER',
      name: 'Welcome to Mr. BARBER.pdf',
      url: '/Welcome-to-Mr-BARBER-v2.pdf',
    },
  ];

  return (
    <>
      <PageHeader title="ENROLMENT" backgroundImage={brushes} icon={razor} />
      <ContentWrapper>
        <SectionHeader title="MR BARBER FILES" />
        <FileTable>
          {files.map(
            ({
              title,
              name,
              url,
            }: {
              title: string;
              name: string;
              url: string;
            }) => (
              <div>
                <div>
                  <span>{title}</span>
                  <a href={`process.env.PUBLIC_URL${url}`} download>
                    {name}
                  </a>
                </div>
                <button>
                  <a href={`process.env.PUBLIC_URL${url}`} download>
                    Download
                  </a>
                </button>
              </div>
            ),
          )}
        </FileTable>
      </ContentWrapper>
    </>
  );
}
