import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { getDayIntervals, getTimeIntervals } from '../hooks/dates';
import { BarberModel } from '../models/barberModel';
import { EventModel } from '../models/eventModel';
import { ServiceModel } from '../models/serviceModel';
import { StoreForm } from '../pages/Bookings';
import { getEvents } from '../services/event.service';
import EditEvent from './EditEvent';
import { FormInput } from './EventForm';
import { LOGIN_EVENT } from './SignIn';
import Snackbar from './Snackbar';

const TODAY = new Date();

const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  height: 75vh;
  th,
  td {
    border: 1px solid #ddd;
    &:first-child {
      width: 80px;
    }
  }
`;

const Slot = styled.td`
  cursor: ${(props: { isDisabled: boolean; appointments: EventModel[] }) =>
    props.isDisabled ? 'normal' : 'pointer'};
  background: ${(props: { isDisabled: boolean; appointments: EventModel[] }) =>
    props.isDisabled ? 'lightgrey' : 'white'};
  div {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
    font-weight: bold;
    padding: 2.5px 0;
    background: ${(props: {
      isDisabled: boolean;
      appointments: EventModel[];
    }) => (props.appointments.length === 3 ? 'lightgrey' : undefined)};
    span {
      color: #666;
    }
  }
  p {
    margin-bottom: 0;
  }
  p:first-of-type {
    margin-top: 0;
  }
`;

export default function SelectableCalendar({
  shop,
  services,
  barbers,
}: {
  shop: number;
  services: ServiceModel[];
  barbers: BarberModel[];
}) {
  const [events, setEvents] = useState<EventModel[]>([]);
  const [day, setDay] = useState<string>(
    new Date().toISOString().substr(0, 10),
  );
  const [showEventModal, setShowEventModal] = useState<EventModel>();
  const [message, setMessage] = useState('');
  const [show, setShow] = useState(false);

  const updateEvents = async () => {
    const newEvents = await getEvents(shop);
    console.log({ newEvents });
    setEvents(
      newEvents.map((event) => {
        return { ...event, start: event.start };
      }),
    );
  };

  const showSnack = () => {
    setMessage('Appointment added successfully.');
    setShow(true);
    setTimeout(() => setShow(false), 5000);
  };

  useEffect(() => {
    window.addEventListener(LOGIN_EVENT, async () => {
      await updateEvents();
    });
  }, []);

  useEffect(() => {
    updateEvents().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [day, shop]);

  const getInterval = () => {
    // eslint-disable-next-line no-restricted-globals
    const screenWidth = screen.width;
    if (screenWidth <= 320) return 1;
    if (screenWidth <= 600) return 3;
    return 7;
  };

  const week = getDayIntervals(new Date(day), getInterval());
  const timeIntervals = getTimeIntervals();

  const newEvent = {
    name: '',
    phone: '',
    start: 0,
    shopId: shop,
    serviceId: 1,
  };

  return (
    <>
      {showEventModal && (
        <EditEvent
          onClose={() => setShowEventModal(undefined)}
          event={showEventModal}
          services={services}
          barbers={barbers}
          update={updateEvents}
          showSnack={showSnack}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <StoreForm onSubmit={(e) => e.preventDefault()}>
          <button
            onClick={() => {
              const newDate = new Date(day);
              setDay(
                new Date(newDate.setDate(newDate.getDate() - getInterval()))
                  .toISOString()
                  .substr(0, 10),
              );
            }}
          >
            <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronLeft} />
          </button>
          <label>
            <span>Select a date:</span>
            <FormInput
              type="date"
              value={day}
              onChange={(e) => {
                setDay(e.target.value);
              }}
            />
          </label>
          <button
            onClick={() => {
              const newDate = new Date(day);
              setDay(
                new Date(newDate.setDate(newDate.getDate() + getInterval() + 1))
                  .toISOString()
                  .substr(0, 10),
              );
            }}
          >
            <FontAwesomeIcon style={{ marginLeft: 5 }} icon={faChevronRight} />
          </button>
        </StoreForm>

        <Table>
          <thead>
            <tr>
              <th />
              {week.map((day) => (
                <th
                  key={day.getTime()}
                >{`${day.getDate()} ${day.toLocaleDateString('en-nz', {
                  weekday: 'short',
                })}`}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {timeIntervals.map((interval) => (
              <tr key={interval.getTime()}>
                <td>
                  {`${
                    interval.getHours() > 12
                      ? interval.getHours() - 12
                      : interval.getHours()
                  }:${
                    interval.getMinutes() === 0 ? '00' : interval.getMinutes()
                  } ${interval.getHours() <= 12 ? 'AM' : 'PM'}`}
                </td>
                {week.map((day) => {
                  const dayAndInterval = new Date(day);
                  dayAndInterval.setHours(interval.getHours());
                  dayAndInterval.setMinutes(interval.getMinutes());

                  const weekday = dayAndInterval.toLocaleDateString('en-nz', {
                    weekday: 'short',
                  });
                  const appointments = events.filter(
                    (event) =>
                      event.start.getTime() === dayAndInterval.getTime(),
                  );
                  const isDisabled =
                    TODAY > dayAndInterval ||
                    (weekday === 'Sat' && dayAndInterval.getHours() >= 15) ||
                    (weekday === 'Sun' && dayAndInterval.getHours() >= 14) ||
                    dayAndInterval.getHours() >= 17 ||
                    appointments.length >= 3;
                  return (
                    <Slot
                      key={day.toString()}
                      isDisabled={isDisabled}
                      appointments={appointments}
                      onClick={
                        isDisabled
                          ? undefined
                          : () =>
                              setShowEventModal({
                                ...newEvent,
                                start: dayAndInterval,
                              } as EventModel)
                      }
                    >
                      {appointments.length > 0 && (
                        <div>
                          <span>
                            {appointments.length === 3 ? <h2>Full</h2> : ''}
                            {localStorage.getItem('password')
                              ? appointments.map((appointment) => (
                                  <React.Fragment key={appointment.phone}>
                                    <p>
                                      {appointment.name} ({appointment.phone})
                                    </p>
                                    <em>
                                      {appointment.barber?.name || 'Any barber'}{' '}
                                      - {appointment.service?.name}
                                    </em>
                                  </React.Fragment>
                                ))
                              : null}
                          </span>
                        </div>
                      )}
                    </Slot>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Snackbar message={message} show={show} />
    </>
  );
}
