import { Link } from 'react-router-dom';
import MrBarberLogo from '../images/logo.webp';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBars,
  faCaretDown,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Fragment, useState } from 'react';
import SignIn from './SignIn';

interface Path {
  label: string;
  path: string;
  children?: { label: string; path: string }[];
}

const Navigation = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
  & nav {
    padding: 20px;
    background: rgba(10, 10, 10, 0.76);
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  & ul {
    margin: 0;
    list-style-type: none;
    display: flex;
    @media only screen and (max-width: 800px) {
      display: none;
    }
  }
  & a {
    text-decoration: none;
    color: white;
  }
  @media (max-width: 800px) {
    padding-left: 0;
  }
`;

const Logo = styled.img`
  width: 73px;
`;

const DropDownContent = styled.div`
  display: none;
  @media (min-width: 800px) {
    position: absolute;
    min-width: 150px;
    background: transparent;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 2;
    border-radius: 0 0 5px 5px;
    & ul {
      display: flex;
      flex-direction: column;
      background: rgb(10, 10, 10);
      margin: 0;
      margin-top: 20px;
      padding: 0;
      & li {
        margin: 0;
        padding: 10px;
        &:first-child {
          border-top: 1px solid white;
        }
      }
    }
  }
  @media (max-width: 800px) {
    border-top: 3px solid #2ea3f2;
    background: rgba(10, 10, 10);
    width: 80%;
    max-height: 400px;
    overflow-y: scroll;
    border-radius: 0 0 5px 5px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    display: ${({ showMenu }: { showMenu?: boolean }) =>
      showMenu ? 'block' : 'none'};
    & div {
      display: flex;
      flex-direction: column;
      & a {
        padding: 15px;
        &:hover {
          color: #bbb;
        }
      }
    }
  }
`;

const DropDown = styled.li`
  position: relative;
  margin: 0 10px;
  font-size: 14px;
  &:hover ${DropDownContent} {
    display: block;
  }
`;

const MobileMenu = styled.div`
  button {
    background: none;
    border: none;
    width: 32px;
    height: 32px;
    cursor: pointer;
  }
  @media only screen and (min-width: 800px) {
    display: none;
    align-self: flex-end;
  }
`;

export const MainNavigation = () => {
  const pages: Path[] = [
    { label: 'Home', path: '/' },
    { label: 'About', path: '/about' },
    {
      label: 'Programs',
      path: '/programs',
      children: [
        {
          label: '12 Week Program',
          path: '/programs/#12-week-program',
        },
        {
          label: 'Cutthroat Shave Classes',
          path: '/programs#cutthroat-shave-classes',
        },
        {
          label: 'Upskill Classes',
          path: '/programs/#upskill-classes',
        },
      ],
    },
    {
      label: 'Book an Appointment',
      path: '/bookings',
    },
    { label: 'Enrolment', path: '/enrolment' },
    { label: 'Gallery', path: '/gallery' },
    {
      label: 'Shops',
      path: '/Shops',
      children: [
        {
          label: 'Training Centre',
          path: '/shops/#training-centre',
        },
        {
          label: 'Epsom Shop',
          path: '/shops/#epsom-shop',
        },
        {
          label: 'Grey Lynn',
          path: '/shops/#grey-lynn',
        },
      ],
    },
  ];

  const [showMenu, setShowMenu] = useState(false);
  const [showSignIn, setShowSignIn] = useState(false);

  return (
    <Navigation>
      <nav>
        <div>
          <Link to={'/'}>
            <Logo src={MrBarberLogo} alt="Mr. Barber" width={73} height={30} />
          </Link>
          <ul>
            {pages.map(({ label, path, children }) => {
              const isDropdown = (children || []).length > 0;
              return (
                <DropDown key={path}>
                  <Link to={path}>
                    {label}
                    {isDropdown ? (
                      <FontAwesomeIcon
                        style={{ marginLeft: 5 }}
                        icon={faCaretDown}
                      />
                    ) : (
                      <></>
                    )}
                  </Link>
                  {isDropdown && (
                    <DropDownContent>
                      <ul>
                        {children!.map((child) => (
                          <li key={child.path}>
                            <Link to={child.path}>{child.label}</Link>
                          </li>
                        ))}
                      </ul>
                    </DropDownContent>
                  )}
                </DropDown>
              );
            })}
          </ul>
          <FontAwesomeIcon
            icon={faSignInAlt}
            color="white"
            style={{ cursor: 'pointer' }}
            onClick={() => setShowSignIn(true)}
          />
          {showSignIn && <SignIn onClose={() => setShowSignIn(false)} />}
          <MobileMenu>
            <button
              onClick={() => setShowMenu(!showMenu)}
              aria-label="Mobile Menu"
            >
              <FontAwesomeIcon icon={faBars} size="2x" color="#2ea3f2" />
            </button>
          </MobileMenu>
        </div>
      </nav>
      {showMenu && (
        <DropDownContent showMenu={showMenu}>
          <div>
            {pages.map((page) => (
              <Fragment key={page.path}>
                <Link to={page.path} onClick={() => setShowMenu(false)}>
                  {page.label}
                </Link>
                {(page.children || []).map((child) => (
                  <Link
                    key={child.path}
                    to={child.path}
                    onClick={() => setShowMenu(false)}
                    style={{ paddingLeft: 40 }}
                  >
                    {child.label}
                  </Link>
                ))}
              </Fragment>
            ))}
          </div>
        </DropDownContent>
      )}
    </Navigation>
  );
};
