import { BarberModel } from '../models/barberModel';
import { graphql } from './graphql.service';

export const getBarber = async (shopId: number): Promise<BarberModel[]> => {
  const query = `#graphql
    query getBarber($shopId: Int) {
        barber(where: {shopId: {_eq: $shopId}}) {
            id
            name
            appointmentDays
        }
    }
`;
  const res = await graphql(query, { shopId });
  const updateData: BarberModel[] = res.data.barber.map((item: any) => ({
    ...item,
    appointmentDays: item.appointmentDays.split(','),
  }));
  return updateData;
};
