import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;

  button {
    background-color: rgba(10, 10, 10, 0.76);
    color: white;
    border: 0;
    border-radius: 5px;
    padding: 10px;
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
  }
`;

export default function Slideshow({ images }: { images: string[] }) {
  const [slide, setSlide] = useState(0);

  return (
    <Container>
      {images.map((image, index) => (
        <img
          key={image}
          src={image}
          alt={image}
          style={{ display: index === slide ? 'block' : 'none' }}
          width={980}
          height={300}
        />
      ))}
      <button
        style={{ position: 'absolute', top: '50%', left: 0 }}
        onClick={() => setSlide(slide === 0 ? images.length - 1 : slide - 1)}
        aria-label="Previous slide"
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </button>
      <button
        style={{ position: 'absolute', top: '50%', right: 0 }}
        onClick={() => setSlide((slide + 1) % images.length)}
        aria-label="Next slide"
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </button>
    </Container>
  );
}
